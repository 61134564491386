















































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { useBlob } from "@/hooks";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { Messages } from "@/models/enums/messages.enum";
import {
  CustomListCategory,
  DataListAssetBook,
  DataResponseCalendar,
} from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { assetsServices } from "@/services/assets.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { currencyFormat } from "@/validator/globalvalidator";
import printJS from "print-js";
import Vue from "vue";
export default Vue.extend({
  data() {
    this.getListAssetBook = debounceProcess(this.getListAssetBook, 200);
    this.getListCalendar = debounceProcess(this.getListCalendar, 200);
    this.getBranch = debounceProcess(this.getBranch, 200);
    return {
      formatCurrency: currencyFormat,
      dataListCategory: [] as CustomListCategory[],
      loadingListAssetCategory: false as boolean,
      loadingSearch: false as boolean,
      loading: {
        download: false,
        find: false,
        print: false,
      },
      visible: false as boolean,
      totalElements: 0 as number,
      limit: 10 as number,
      form: this.$form.createForm(this, { name: "reportAssetAdjustment" }),
      page: this.page || (1 as number),
      loadingListAssetBook: false as boolean,
      loadingBranch: false as boolean,
      dataCalendar: [] as DataResponseCalendar[],
      dataListBook: [] as DataListAssetBook[],
      dataBranch: [] as DataWarehouseBranch[],
      dataDownload: {} as any,
      loadingCalendar: false as boolean,
      dataSource: {} as any,
      dataListAdjustmentType: {} as any,
      formRules: {
        assetNumber: {
          label: "lbl_asset_number",
          name: "assetNumber",
          placeholder: "lbl_asset_number_placeholder",
          decorator: [
            "assetNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        category: {
          label: "lbl_category",
          name: "category",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "category",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        periode: {
          label: "lbl_period",
          name: "periode",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "periode",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        adjustmentType: {
          label: "lbl_adjustment_type",
          name: "adjustmentType",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "adjustmentType",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_branch_placeholder",
          decorator: [
            "branch",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      columnsTable: [
        {
          title: this.$t("lbl_asset_number"),
          dataIndex: "assetNo",
          key: "assetNumber",
          width: 200,
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          width: 200,
        },
        {
          title: this.$t("lbl_equipment"),
          dataIndex: "equipment",
          key: "equipment",
          width: 200,
        },
        {
          title: this.$t("lbl_merk"),
          dataIndex: "merk",
          key: "merk",
          width: 200,
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          key: "type",
          width: 200,
        },
        {
          title: this.$t("lbl_model"),
          dataIndex: "model",
          key: "model",
          width: 200,
        },
        {
          title: this.$t("lbl_sn"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 200,
        },
        {
          title: this.$t("lbl_spec"),
          dataIndex: "specification",
          key: "specification",
          width: 200,
        },
        {
          title: this.$t("lbl_cost"),
          dataIndex: "cost",
          key: "cost",
          width: 200,
        },
        {
          title: this.$t("lbl_asset_book"),
          dataIndex: "bookName",
          key: "assetBooks",
          width: 200,
        },
        {
          title: this.$t("lbl_adjustment_type"),
          dataIndex: "assetAdjustmentType",
          key: "assetAdjustmentType",
          width: 200,
        },
        {
          title: this.$t("lbl_adjustment"),
          dataIndex: "description",
          key: "adjustment",
          scopedSlots: { customRender: "isRenderTag" },
          width: 500,
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          width: 200,
        },
        {
          title: this.$t("lbl_warehouse"),
          dataIndex: "location",
          key: "location",
          width: 200,
        },
      ],
    };
  },
  mounted() {
    this.getListAssetBook("");
    this.getListCalendar("");
    this.getListOfMasterType();
    this.getListAssetCategories("");
    this.getBranch("");
  },

  methods: {
    getBranch(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loadingBranch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loadingBranch = false));
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.submitForm();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.submitForm();
    },
    getListAssetCategories(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search = `category.id~*${valueSearch}*_OR_description~*${valueSearch}`;
      this.loadingListAssetCategory = true;
      assetsServices
        .listAssetCategory(params)
        .then(response => {
          let tempDataSegments = [] as CustomListCategory[];
          response.data.forEach(data => {
            let tempSegment = {
              id: data.id,
              first: "",
              second: "",
            } as CustomListCategory;
            data.category.segments.forEach((dataSegment, index) => {
              if (dataSegment.first) {
                tempSegment.first += dataSegment.first;
              }
              if (dataSegment.second) {
                tempSegment.second += dataSegment.second;
              }
              if (index % 2 === 0) {
                tempSegment.first += ".";
              }
            });
            tempDataSegments.push(tempSegment);
          });
          this.dataListCategory = tempDataSegments;
        })
        .finally(() => (this.loadingListAssetCategory = false));
    },
    getListOfMasterType(): void {
      let params = {
        name: "ADJUSTMENT_TYPE",
      } as RequestQueryParamsModel;
      masterServices.listMaster(params).then((res: any) => {
        res.map((dataObject, index) => (dataObject.key = index));
        this.dataListAdjustmentType = res;
      });
    },
    downloadReport(): void {
      const params: RequestQueryParamsModel = {
        page: 0,
      };
      const arrSearch: string[] = [];
      // Object.keys(this.dataDownload).forEach((key) =>{
      //     if (this.dataDownload[key]){
      //       if (key === 'assetNumber') arrSearch.push(`masterAsset.assetNo~${this.dataDownload.assetNumber}`)
      //       if (key === 'periode') arrSearch.push(`period~${this.dataDownload.periode}`)
      //       if (key === 'adjustmentType') arrSearch.push(`assetAdjustmentType~${this.dataDownload.adjustmentType}`)
      //     }
      // });
      // const elementsJoin = arrSearch
      // if (arrSearch.length > 0) params.search = elementsJoin.join('_OR_')
      let arrParams = [
        this.$store.state.authStore.authData.companyName,
        "ALL",
        "ALL",
        "ALL",
        "ALL",
      ] as any;
      Object.keys(this.dataDownload).forEach(key => {
        if (this.dataDownload[key]) {
          if (key === "branch") {
            arrSearch.push(
              `location.warehouse.branchWarehouse.secureId~${this.dataDownload.branch}`
            );
            arrParams[1] = this.dataBranch.find(
              dataFind => dataFind.id === this.dataDownload["branch"]
            )?.name;
          }
          if (key === "assetNumber") {
            arrSearch.push(
              `masterAsset.assetNo~${this.dataDownload.assetNumber}`
            );
            arrParams[2] = this.dataDownload.assetNumber;
          }
          if (key === "periode") {
            arrSearch.push(`period~${this.dataDownload.periode}`);
            arrParams[3] = this.dataDownload.periode;
          }
          if (key === "adjustmentType") {
            arrSearch.push(
              `assetAdjustmentType~${this.dataDownload.adjustmentType}`
            );
            arrParams[4] = this.dataDownload.adjustmentType;
          }
        }
      });
      const elementsJoin = arrSearch;
      if (arrSearch.length > 0) params.search = elementsJoin.join("_OR_");
      if (arrParams.length > 0) params.params = arrParams.join();
      const { toDownload } = useBlob();
      this.loading.download = true;
      assetsServices
        .getDownloadReportAssetAdjustment(params)
        .then(response => {
          toDownload(response, "asset_adjustment_report.xlsx");
        })
        .finally(() => {
          this.loading.download = false;
        });
    },
    printReport(): void {
      const params: RequestQueryParamsModel = {
        page: 0,
      };
      let arrSearch = [] as any;
      let arrParams = [
        this.$store.state.authStore.authData.companyName,
        "ALL",
        "ALL",
        "ALL",
        "ALL",
      ] as any;
      Object.keys(this.dataDownload).forEach(key => {
        if (this.dataDownload[key]) {
          if (key === "branch") {
            arrSearch.push(
              `location.warehouse.branchWarehouse.secureId~${this.dataDownload.branch}`
            );
            arrParams[1] = this.dataBranch.find(
              dataFind => dataFind.id === this.dataDownload["branch"]
            )?.name;
          }
          if (key === "assetNumber") {
            arrSearch.push(
              `masterAsset.assetNo~${this.dataDownload.assetNumber}`
            );
            arrParams[2] = this.dataDownload.assetNumber;
          }

          if (key === "periode") {
            arrSearch.push(`period~${this.dataDownload.periode}`);
            arrParams[3] = this.dataDownload.periode;
          }

          if (key === "adjustmentType") {
            arrSearch.push(
              `assetAdjustmentType~${this.dataDownload.adjustmentType}`
            );
            arrParams[4] = this.dataDownload.adjustmentType;
          }
        }
      });
      const elementsJoin = arrSearch;
      if (arrSearch.length > 0) params.search = elementsJoin.join("_OR_");
      if (arrParams.length > 0) params.params = arrParams.join();

      const { toObjectUrl } = useBlob();
      this.loading.print = true;
      assetsServices
        .getPrintReportAssetAdjustment(params)
        .then(response => {
          printJS(toObjectUrl(response));
        })
        .finally(() => {
          this.loading.print = false;
        });
    },
    getListCalendar(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `month~*${valueSearch}*`;
      this.loadingCalendar = true;
      let dataPeriode = [] as any;
      this.dataCalendar = [];
      assetsServices
        .listCalendar(params)
        .then(data => {
          data.data.forEach(element => {
            if (!element.month.includes("Adj")) dataPeriode.push(element);
          });
          this.dataCalendar = dataPeriode;
        })
        .finally(() => (this.loadingCalendar = false));
    },
    submitForm() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let params = {
            limit: this.limit,
            page: this.page - 1,
            sorts: "createdDate:desc",
          } as RequestQueryParamsModel;
          let arrSearch = [] as any;
          let arrParams = [
            this.$store.state.authStore.authData.companyName,
            "ALL",
            "ALL",
            "ALL",
            "ALL",
          ] as any;
          Object.keys(values).forEach(key => {
            if (values[key]) {
              if (key === "branch") {
                arrSearch.push(
                  `location.warehouse.branchWarehouse.secureId~${values.branch}`
                );
                arrParams[1] = this.dataBranch.find(
                  dataFind => dataFind.id === values["branch"]
                )?.name;
              }
              if (key === "assetNumber") {
                arrSearch.push(`masterAsset.assetNo~${values.assetNumber}`);
                arrParams[2] = values.assetNumber;
              }
              if (key === "periode") {
                arrSearch.push(`period~${values.periode}`);
                arrParams[3] = values.periode;
              }
              if (key === "adjustmentType") {
                arrSearch.push(`assetAdjustmentType~${values.adjustmentType}`);
                arrParams[4] = values.adjustmentType;
              }
            }
          });
          const elementsJoin = arrSearch;
          if (arrSearch.length > 0) params.search = elementsJoin.join("_OR_");
          if (arrParams.length > 0) params.params = arrParams.join();
          this.dataDownload = {
            branch: values.branch,
            periode: values.periode,
            assetNumber: values.assetNumber,
            adjustmentType: values.adjustmentType,
          };
          this.loading.find = true;
          assetsServices
            .getReportAssetAdjustment(params)
            .then(data => {
              data.data.forEach((dataObject, index) => {
                dataObject.cost = this.formatCurrency(dataObject.cost);
                dataObject.key = index;
              });
              this.visible = false;
              this.totalElements = data.totalElements;
              this.dataSource = data;
            })
            .finally(() => (this.loading.find = false));
        } else {
          this.$notification["error"]({
            message: "Error",
            description: "Form is mandatory",
          });
        }
      });
    },

    getListAssetBook(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        search: `type~commercial_OR_active~true`,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 10 },
      };
    },
  },
});
